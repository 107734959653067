import { EmailError } from '../../../enums/email';
export var FormMessage;
(function (FormMessage) {
    FormMessage["None"] = "";
    FormMessage["RequiredError"] = "h24_newsletter_email_required_error";
    FormMessage["AlreadySubscribed"] = "h24_newsletter_subscription_error";
    FormMessage["RequestError"] = "h24_something_went_wrong";
    FormMessage["RequestSuccess"] = "h24_newsletter_subscription_success";
})(FormMessage || (FormMessage = {}));
var getFieldError = function (_a) {
    var valueMissing = _a.valueMissing, typeMismatch = _a.typeMismatch;
    return valueMissing
        ? FormMessage.RequiredError
        : typeMismatch
            ? EmailError.InvalidEmail
            : FormMessage.None;
};
export var getValidationError = function (form) {
    var emailInput = form.elements.email;
    var _a = emailInput.validity, valid = _a.valid, valueMissing = _a.valueMissing, typeMismatch = _a.typeMismatch;
    return valid ? null : getFieldError({ valueMissing: valueMissing, typeMismatch: typeMismatch });
};
