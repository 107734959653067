var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { border, borderColor, borderRadius, borderStyle, borderWidth, color, fontSize, space, themeGet, width, } from 'styled-system';
import { boxSizing } from '../../theme/system-utilities';
/**
 * @deprecated
 *
 * Use StatefulInput instead
 */
var Input = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:active,\n  &:focus {\n    outline: none;\n    border-color: ", ";\n  }\n\n  &::placeholder {\n    color: ", ";\n  }\n\n  ", "\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:active,\n  &:focus {\n    outline: none;\n    border-color: ", ";\n  }\n\n  &::placeholder {\n    color: ", ";\n  }\n\n  ", "\n"])), width, fontSize, space, boxSizing, borderRadius, borderStyle, borderWidth, borderColor, border, color, themeGet('colors.sys.neutral.border.active'), themeGet('colors.sys.neutral.background.inverted'), function (_a) {
    var hasError = _a.hasError, colors = _a.theme.colors;
    return hasError && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      border-color: ", ";\n    "], ["\n      border-color: ", ";\n    "])), colors.sys.primary.text.default);
});
Input.defaultProps = {
    width: 1,
    fontSize: 'fs_16',
    color: 'sys.neutral.border.strong',
    mt: 'sp_0',
    mb: ['sp_16', null, 'sp_0'],
    px: ['sp_16', null, null, 'sp_20'],
    py: 'sp_8',
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: 'small',
    borderColor: 'sys.neutral.border.inactive',
    borderRadius: 'small',
};
export default Input;
var templateObject_1, templateObject_2;
