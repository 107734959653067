export var EmailErrorCode;
(function (EmailErrorCode) {
    EmailErrorCode["InvalidEmail"] = "invalid_email";
    EmailErrorCode["InvalidDomain"] = "invalid_domain";
    EmailErrorCode["RejectedEmail"] = "rejected_email";
    EmailErrorCode["InvalidSMTP"] = "invalid_smtp";
    EmailErrorCode["NoConnect"] = "no_connect";
    EmailErrorCode["Timeout"] = "timeout";
    EmailErrorCode["UnavailableSMTP"] = "unavailable_smtp";
    EmailErrorCode["UnexpectedError"] = "unexpected_error";
    EmailErrorCode["AcceptedEmail"] = "accepted_email";
    EmailErrorCode["LowQuality"] = "low_quality";
    EmailErrorCode["LowDeliverability"] = "low_deliverability";
    EmailErrorCode["UserWithEmailExists"] = "USER_WITH_EMAIL_EXISTS";
})(EmailErrorCode || (EmailErrorCode = {}));
export var EmailError;
(function (EmailError) {
    EmailError["InvalidEmail"] = "h24_validation_error_form_emailAddress_pattern";
    EmailError["RequestTryAgain"] = "h24_newsletter_try_again";
    EmailError["UserWithEmailExists"] = "h24_validation_error_email_user_with_email_exists";
})(EmailError || (EmailError = {}));
