var _a;
import { EmailError, EmailErrorCode } from '../enums/email';
export var EMAIL_ERRORS_MAPPING = (_a = {},
    _a[EmailErrorCode.InvalidEmail] = EmailError.InvalidEmail,
    _a[EmailErrorCode.InvalidDomain] = EmailError.InvalidEmail,
    _a[EmailErrorCode.RejectedEmail] = EmailError.InvalidEmail,
    _a[EmailErrorCode.InvalidSMTP] = EmailError.RequestTryAgain,
    _a[EmailErrorCode.NoConnect] = EmailError.RequestTryAgain,
    _a[EmailErrorCode.Timeout] = EmailError.RequestTryAgain,
    _a[EmailErrorCode.UnavailableSMTP] = EmailError.RequestTryAgain,
    _a[EmailErrorCode.UnexpectedError] = EmailError.RequestTryAgain,
    _a[EmailErrorCode.UserWithEmailExists] = EmailError.UserWithEmailExists,
    _a);
