var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SubscriptionTypeAnon } from 'graphql-ts-types';
import { EventAction, EventCategory, NewsletterSignupEvent, NewsletterSignupName, } from '../../../enums/Tracking';
var TRACKING_NEWSLETTER_TYPE_BUTLERS = 'Butlers';
export var getTrackingData = function (_a) {
    var event = _a.event, tracking = _a.tracking, visitorHash = _a.visitorHash, newsletterType = _a.newsletterType;
    return ({
        event: event,
        eventAction: event === NewsletterSignupEvent.NewsLetterSignUpClick
            ? EventAction.Click
            : EventAction.Success,
        eventCategory: EventCategory.Newsletter,
        eventData: __assign(__assign(__assign({ name: (tracking === null || tracking === void 0 ? void 0 : tracking.name) || NewsletterSignupName.NewsletterSignUp }, tracking), (visitorHash && { visitorHash: visitorHash })), (newsletterType === SubscriptionTypeAnon.ButlersNewsletter && {
            type: TRACKING_NEWSLETTER_TYPE_BUTLERS,
        })),
    });
};
