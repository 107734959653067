var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { alignItems, border, borderColor, borderRight, color, display, flex, fontSize, height, lineHeight, maxWidth, position, size, space, textAlign, themeGet, width, zIndex, } from 'styled-system';
import Icon from '../../components/Icon';
import { borderRadiusBL, borderRadiusBR, borderRadiusTL, borderRadiusTR, fill, themeRight, themeTop, } from '../../theme/system-utilities';
import { Button } from '../Button';
import Input from '../Input';
export var FormStateType;
(function (FormStateType) {
    FormStateType[FormStateType["Default"] = 0] = "Default";
    FormStateType[FormStateType["Success"] = 1] = "Success";
    FormStateType[FormStateType["Warning"] = 2] = "Warning";
    FormStateType[FormStateType["Error"] = 3] = "Error";
})(FormStateType || (FormStateType = {}));
var ICON_SIZE = 32;
var SPINNER_SIZE = 20;
var SubmitInputForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), display, width);
SubmitInputForm.defaultProps = {
    display: 'flex',
    width: 1,
};
var FieldIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), size, position, themeTop, themeRight, fill);
FieldIcon.defaultProps = {
    size: ICON_SIZE,
    position: 'absolute',
    themeRight: 'sp_4',
    themeTop: '7px',
};
var SubmitInputLabel = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), flex, position, display);
SubmitInputLabel.defaultProps = {
    position: 'relative',
    display: 'flex',
    flex: 1,
};
var SubmitInputInput = styled(Input)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", ";\n\n  &:active,\n  &:focus {\n    border-color: ", ";\n  }\n  &:disabled {\n    background-color: ", ";\n    color: ", ";\n    -webkit-text-fill-color: ", ";\n    opacity: 1;\n  }\n  &::placeholder {\n    color: ", ";\n  }\n  &::-ms-clear {\n    display: none;\n  }\n\n  &[type='email'] {\n    -webkit-appearance: none;\n    height: 100% !important;\n\n    &:invalid {\n      box-shadow: none !important;\n    }\n\n    &::-webkit-search-decoration,\n    &::-webkit-search-cancel-button,\n    &::-webkit-search-results-button,\n    &::-webkit-search-results-decoration {\n      -webkit-appearance: none;\n    }\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", ";\n\n  &:active,\n  &:focus {\n    border-color: ", ";\n  }\n  &:disabled {\n    background-color: ", ";\n    color: ", ";\n    -webkit-text-fill-color: ", ";\n    opacity: 1;\n  }\n  &::placeholder {\n    color: ", ";\n  }\n  &::-ms-clear {\n    display: none;\n  }\n\n  &[type='email'] {\n    -webkit-appearance: none;\n    height: 100% !important;\n\n    &:invalid {\n      box-shadow: none !important;\n    }\n\n    &::-webkit-search-decoration,\n    &::-webkit-search-cancel-button,\n    &::-webkit-search-results-button,\n    &::-webkit-search-results-decoration {\n      -webkit-appearance: none;\n    }\n  }\n"])), border, borderRight, borderColor, color, space, borderRadiusTR, borderRadiusBR, function (_a) {
    var isHome24 = _a.theme.isHome24;
    return !isHome24 && 'border-radius: 0;';
}, function (_a) {
    var hasIcon = _a.hasIcon;
    return hasIcon && 'padding-right: 32px;';
}, function (_a) {
    var activeColor = _a.activeColor, props = __rest(_a, ["activeColor"]);
    return themeGet("colors.".concat(activeColor))(props);
}, function (props) {
    return props.buttonBackgroundColor
        ? themeGet("colors.".concat(props.buttonBackgroundColor))(props)
        : themeGet('colors.sys.neutral.background.active')(props);
}, themeGet('colors.sys.neutral.border.default'), themeGet('colors.sys.neutral.border.default'), themeGet('colors.sys.neutral.border.default'));
SubmitInputInput.defaultProps = __assign(__assign({}, Input.defaultProps), { px: 'sp_12', borderRight: 'none', border: '1px solid', color: 'sys.neutral.border.hover', mb: 'sp_0', borderRadiusTR: 0, borderRadiusBR: 0 });
var SubmitInputButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:disabled {\n    border-color: ", ";\n  }\n  ", "\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:disabled {\n    border-color: ", ";\n  }\n  ", "\n"])), borderColor, borderRadiusTL, borderRadiusBL, height, display, fontSize, lineHeight, alignItems, zIndex, themeGet('colors.sys.neutral.background.inverted'), function (_a) {
    var kind = _a.kind, props = __rest(_a, ["kind"]);
    return kind === 'secondary' &&
        "\n    > svg[role=presentation] {\n      stroke: ".concat(themeGet('colors.sys.neutral.background.inverted')(props), ";\n    }\n  ");
});
SubmitInputButton.defaultProps = {
    display: 'flex',
    fontSize: 'fs_16',
    lineHeight: 'lh_20',
    alignItems: 'center',
    boxShadow: 'none',
    borderRadiusTL: 0,
    borderRadiusBL: 0,
    px: 'sp_8',
    py: 'sp_12',
    zIndex: 1,
};
var SubmitInputMessage = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, display, fontSize, lineHeight, textAlign, width, maxWidth, color);
SubmitInputMessage.defaultProps = {
    mt: 'sp_4',
    fontSize: 'fs_14',
    lineHeight: 'lh_20',
    textAlign: 'left',
    width: 1,
    maxWidth: [null, null, '511px'],
};
var getPropsForFieldState = function (state) {
    switch (state) {
        case FormStateType.Success:
            return {
                stateColor: 'sys.success.text.default',
                icon: 'success',
            };
        case FormStateType.Error:
            return {
                stateColor: 'sys.error.text.default',
                icon: 'error',
            };
        case FormStateType.Warning:
            return {
                stateColor: 'sys.warning.text.default',
                icon: 'warning',
            };
        default:
            return { stateColor: 'sys.neutral.text.default' };
    }
};
var submitInputs = 0;
/* eslint-disable max-lines */
var SubmitInput = function (_a) {
    var inputLabel = _a.inputLabel, inputType = _a.inputType, inputName = _a.inputName, inputDisabled = _a.inputDisabled, inputRequired = _a.inputRequired, onChange = _a.onChange, onSubmit = _a.onSubmit, onBlur = _a.onBlur, value = _a.value, state = _a.state, maxLength = _a.maxLength, buttonLabel = _a.buttonLabel, buttonContent = _a.buttonContent, buttonKind = _a.buttonKind, buttonBorderColor = _a.buttonBorderColor, buttonColor = _a.buttonColor, buttonDisabled = _a.buttonDisabled, isLoading = _a.isLoading, message = _a.message, className = _a.className, inputMode = _a.inputMode, tracking = _a.tracking, restProps = __rest(_a, ["inputLabel", "inputType", "inputName", "inputDisabled", "inputRequired", "onChange", "onSubmit", "onBlur", "value", "state", "maxLength", "buttonLabel", "buttonContent", "buttonKind", "buttonBorderColor", "buttonColor", "buttonDisabled", "isLoading", "message", "className", "inputMode", "tracking"]);
    var _b = getPropsForFieldState(state), stateColor = _b.stateColor, icon = _b.icon;
    var handleSubmit = useCallback(function (event) {
        event.preventDefault();
        if (!isLoading) {
            onSubmit(event);
        }
    }, [isLoading, onSubmit]);
    var idNumber = useMemo(function () { return submitInputs++; }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(SubmitInputForm, { onSubmit: handleSubmit, noValidate: true, className: className },
            React.createElement(SubmitInputLabel, null,
                React.createElement(SubmitInputInput, __assign({ "aria-describedby": "submit-input-message-".concat(idNumber), "aria-label": inputLabel, type: inputType, name: inputName, inputMode: inputMode, value: value, maxLength: maxLength, onChange: onChange, onBlur: onBlur, placeholder: inputLabel, disabled: inputDisabled, borderColor: stateColor, required: inputRequired, activeColor: FormStateType.Default === state ? 'sys.neutral.border.hover' : stateColor, hasIcon: !!icon }, restProps)),
                icon && React.createElement(FieldIcon, { name: icon, fill: stateColor })),
            React.createElement(SubmitInputButton, { type: "submit", disabled: buttonDisabled, isLoading: isLoading, kind: buttonKind, color: buttonColor, borderColor: buttonBorderColor, "aria-label": buttonLabel, tracking: tracking, spinnerSize: SPINNER_SIZE }, buttonContent)),
        message && (React.createElement(SubmitInputMessage, { color: stateColor, "data-testid": "message", id: "submit-input-message-".concat(idNumber), role: "alert" }, message))));
};
SubmitInput.defaultProps = {
    inputType: 'sys.neutral.text.strong',
    buttonKind: 'primary',
};
export default SubmitInput;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
