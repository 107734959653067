var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { display, fontSize, space, size } from 'styled-system';
import Icon from '../Icon';
var EMAIL_ICON_SIZE = 20;
var SubmitButtonText = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), display, fontSize);
SubmitButtonText.defaultProps = {
    display: ['none', 'block'],
    fontSize: 'fs_16',
};
var SubscribeIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), size, display, space);
SubscribeIcon.defaultProps = {
    dataTestId: 'subscribe-icon',
    role: 'presentation',
    display: ['block', 'none'],
    size: EMAIL_ICON_SIZE,
    mx: 'sp_4',
};
export var EmailSubmitButton = function (_a) {
    var children = _a.children;
    return (React.createElement(Fragment, null,
        React.createElement(SubscribeIcon, { name: "subscribe" }),
        React.createElement(SubmitButtonText, { "data-testid": "submit-button-label" }, children)));
};
var templateObject_1, templateObject_2;
