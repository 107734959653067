import React from 'react';
import { useLocalization } from '../../providers/LocaleProvider';
import SubmitInput, { FormStateType } from '../SubmitInput';
import { EmailSubmitButton } from './EmailSubmitButton';
import useNewsletterSubscription from './hooks';
import { FormMessage } from './utils/validation';
var NewsletterSignupInput = function (_a) {
    var initialState = _a.initialState, initialMessage = _a.initialMessage, initialIsLoading = _a.initialIsLoading, tracking = _a.tracking, promoClickOnSubmit = _a.promoClickOnSubmit, buttonKind = _a.buttonKind, newsletterType = _a.newsletterType;
    var _b = useNewsletterSubscription({
        onSubmit: promoClickOnSubmit,
        initialState: initialState,
        initialMessage: initialMessage,
        initialIsLoading: initialIsLoading,
        tracking: tracking,
        newsletterType: newsletterType,
    }), value = _b.value, isLoading = _b.isLoading, message = _b.message, state = _b.state, onChange = _b.onChange, onSubmit = _b.onSubmit;
    var t = useLocalization().t;
    return (React.createElement(SubmitInput, { inputLabel: t('h24_newsletter_input_placeholder'), inputType: "email", inputName: "email", inputRequired: true, state: state, value: value, onChange: onChange, onSubmit: onSubmit, isLoading: isLoading, buttonLabel: t('h24_newsletter_submit_button'), buttonKind: buttonKind, buttonContent: React.createElement(EmailSubmitButton, null, t('h24_newsletter_submit_button')), buttonDisabled: state === FormStateType.Success, message: message && t(message) }));
};
NewsletterSignupInput.defaultProps = {
    initialState: FormStateType.Default,
    initialMessage: FormMessage.None,
};
export default React.memo(NewsletterSignupInput);
